import { ErrorDetails } from './error-details';
import { ObjectHelper } from '../helpers/object-helper';
import { HttpStatusCode } from '../enums/http-status-codes';

export class ErrorModel {

    public Time: string;
    public Error: ErrorDetails;

    public get ErrorMessage(): string {
        return this.Error.detail;
    }
    public get ErrorDetails(): ErrorDetails {
        return this.Error as ErrorDetails;
    }

    public get status(): HttpStatusCode {
        return this.ErrorDetails.status;
    }


    constructor(
        private ErrorCtor: string | ErrorDetails,
        public Translatable?: boolean
    ) {
        this.Time = new Date().toTimeString();

        if (typeof ErrorCtor !== 'string') {
            this.Error = new ErrorDetails(ObjectHelper.deepCopyJsonParse(ErrorCtor));
            this.Translatable = true;
        } else {
            this.Error = new ErrorDetails(this.ErrorCtor);
            this.Translatable = !!this.Translatable;
        }
    }
}
