import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorModel } from 'src/app/models/error-model';
import { Subject } from 'rxjs';
import { HttpStatusCode } from '../../enums/http-status-codes';
import { TranslocoService } from '@ngneat/transloco';
import { ErrorDetails } from 'src/app/models/error-details';
import { ResourceExceptionDetails } from 'src/app/models/resource-exception-details';
import { HashMap } from '@ngneat/transloco/lib/types';
import { ObjectHelper } from '../../helpers/object-helper';

@Injectable({
    providedIn: 'root'
})
export class ErrorManagerService {

    public AppConnectedToServer: boolean = false;

    private _errors: ErrorModel[] = [];
    private _errorSubject: Subject<ErrorModel> = new Subject<ErrorModel>();

    public get Error(): Subject<ErrorModel> {
        return this._errorSubject;
    }

    constructor(private _router: Router, private translocoService: TranslocoService) { }

    private translateResourceExceptionDetails(resource: ResourceExceptionDetails): string {
        if (resource.translatable) {
            const resourceParameters = resource.parameters ?? {};

            let parameters: HashMap = {};

            Object.keys(resourceParameters).forEach(key => {
                parameters[key] = this.translateResourceExceptionDetails(resourceParameters[key]);
            });

            return this.translocoService.translate(resource.resourceId, parameters);
        } else {
            return resource.untranslatedResource;
        }
    }

    public translateErrorDetails(errorDetails?: ErrorDetails): string {
        if (errorDetails?.landfolioFrameworkApi?.resource) {
            return this.translateResourceExceptionDetails(errorDetails.landfolioFrameworkApi.resource);
        } else {
            return '';
        }
    }

    public AddError(error: ErrorModel): void {
        this._errors.push(error);
    }

    public ClearError(): void {
        this._errors = [];
    }

    public GetAll(): ErrorModel[] {
        return this._errors;
    }

    public HandleError(error: ErrorModel): void {
        //if signal-r is not connected, we don't want to navigate to the error page.
        //when the signal-r connection is restored the page will refresh, and we want that to happen on the original page.
        const pageNotFound = error.status === HttpStatusCode.NOT_FOUND;

        const unauthenticated = error.status === HttpStatusCode.UNAUTHORIZED;

        const unauthorized = error.status === HttpStatusCode.FORBIDDEN;

        //this is a hack so the app component can perform logic for errors with status 503 (HttpStatusCode.SERVICE_UNAVAILABLE) and 401 (HttpStatusCode.UNAUTHORIZED)
        this._errorSubject.next(error);

        //another hack. don't redirect to error page just to tell the user they need to log in
        //the system will auto logout at this point anyway
        if (!unauthenticated) {
            if (this.AppConnectedToServer || pageNotFound || unauthorized) {
                this.AddError(error);
                this._router.navigate(['error']);
            } else {
                console.log(error);
            }
        }
    }
}
