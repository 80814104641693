import { HttpStatusCode } from '../enums/http-status-codes';
import { LandfolioFrameworkApiException } from './landfolio-framework-api-exception';
import { GuidHelper } from '../helpers/guid-helper';

export class ErrorDetails {
    public title: string;
    public type: string;
    public detail: string;
    public instance: string;
    public exceptionId: string;
    public status: HttpStatusCode;
    public timestamp: Date;
    public errorMessageResourceId: string;
    public landfolioFrameworkApi: LandfolioFrameworkApiException;

    constructor();
    constructor(errorObj: any);
    constructor(errorObj: any, status: HttpStatusCode);

    constructor(errorObj?: any, status?: HttpStatusCode) {
        const detailStatus = isNaN(parseInt(errorObj?.status))
            ? null
            : parseInt(errorObj?.status) as HttpStatusCode;

        this.title = (errorObj?.title as string) || '';
        this.type = (errorObj?.type as string) || '';
        this.detail = errorObj?.detail as string;
        this.instance = (errorObj?.instance as string) || '';
        this.exceptionId = (errorObj?.exceptionId as string) || GuidHelper.EmptyGuid();
        this.status = detailStatus || status || HttpStatusCode.INTERNAL_SERVER_ERROR;
        this.timestamp = (errorObj?.timestamp as Date) || new Date();
        this.errorMessageResourceId = (errorObj?.errorMessageResourceId as string) || '';
        this.landfolioFrameworkApi = (errorObj?.landfolioFrameworkApi as LandfolioFrameworkApiException) || null;
        if (typeof errorObj !== 'string') {
            this.detail ||= `(${this.status} - ${HttpStatusCode[this.status]}): Unknown server error occured`;
        }
        else {
            this.detail ||= errorObj;
        }

    }
}