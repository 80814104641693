<div [ngSwitch]="true">
    <div *ngSwitchCase="DocumentRowOptions?.formEditStyle === formEditStyle.Inline || DocumentRowOptions?.formEditStyle === formEditStyle.ReadOnly" class="doc-view">
        <div class="doc-contents">
            <span class="pi pi-file"></span>
            <div class="doc-details">
                <span *ngIf="displayField('DocumentType')" class="doc-type">{{getDocumentTypeLookupValue()}}</span>
                <div>
                    <span *ngIf="displayField('FileName')" class="doc-name">{{DocumentListViewDTO.FileName}}</span>
                    <span *ngIf="displayField('Size')" class="doc-size">{{formatDocumentSize(DocumentListViewDTO.Size)}}</span>
                    <div *ngIf="displayField('LastUpdatedDate')">
                        <fw-list-calendar-wrapper [Date]="DocumentListViewDTO.LastUpdatedDate" [IsRoutingField]="false"></fw-list-calendar-wrapper>
                    </div>


                </div>
            </div>
            <p-progressSpinner *ngIf="isLoading"></p-progressSpinner>
        </div>
        <div class="doc-btns">
            <button pButton [disabled]="isOperationInProgress" icon="pi pi-sort-alt" iconPos="left" *ngIf="operationEnabled(documentOperation.Replace) && DocumentRowOptions?.formEditStyle !== formEditStyle.ReadOnly" class="btn doc-btn-replace p-button-sm" (click)="replaceDocument()" label="{{'Shared.Button.Replace' | transloco}}"></button>
            <button pButton [disabled]="isOperationInProgress" icon="pi pi-download" iconPos="left" *ngIf="canDownloadDocument" class="btn doc-btn-download p-button-sm" (click)="dowloadDocument()" label="{{'Shared.Button.Download' | transloco}}"></button>
            <button pButton [disabled]="isOperationInProgress" icon="pi pi-link" iconPos="left" *ngIf="operationEnabled(documentOperation.Remove) && DocumentRowOptions?.formEditStyle !== formEditStyle.ReadOnly" class="btn doc-btn-remove p-button-sm" (click)="deleteDocument()" label="{{'Shared.Button.Remove' | transloco}}"></button>
        </div>
    </div>
    <div *ngSwitchCase="DocumentRowOptions?.formEditStyle === formEditStyle.Form" class="doc-upload">
        <div class="doc-details">
            <span *ngIf="displayField('DocumentType')" class="doc-type">{{getDocumentTypeLookupValue()}}</span>
            <div>
                <span *ngIf="displayField('FileName')" class="doc-name">{{DocumentListViewDTO.FileName}}</span>
                <span *ngIf="displayField('Size')" class="doc-size">{{formatDocumentSize(DocumentListViewDTO.Size)}}</span>
                <div *ngIf="displayField('LastUpdatedDate')">
                    <fw-list-calendar-wrapper [Date]="DocumentListViewDTO.LastUpdatedDate" [IsRoutingField]="false"></fw-list-calendar-wrapper>
                </div>
            </div>
        </div>
        <div class="doc-btns">
            <p-progressSpinner *ngIf="isLoading"></p-progressSpinner>
            <button pButton [disabled]="isOperationInProgress" icon="pi pi-upload" iconPos="left" *ngIf="operationEnabled(documentOperation.Add) && !hasFileUploaded" class="btn doc-btn-upload p-button-sm" (click)="uploadDocument()" label="{{'Shared.Button.Upload' | transloco}}"></button>
            <button pButton [disabled]="isOperationInProgress" icon="pi pi-sort-alt" *ngIf="operationEnabled(documentOperation.Replace) && hasFileUploaded" class="btn doc-btn-replace p-button-sm" (click)="replaceDocument()" label="{{'Shared.Button.Replace' | transloco}}"></button>
            <button pButton [disabled]="isOperationInProgress" icon="pi pi-download" iconPos="left" *ngIf="operationEnabled(documentOperation.Get) && hasFileUploaded" class="btn doc-btn-download p-button-sm" (click)="dowloadDocument()" label="{{'Shared.Button.Download' | transloco}}"></button>
            <button pButton [disabled]="isOperationInProgress" icon="pi pi-trash" iconPos="left" *ngIf="operationEnabled(documentOperation.Remove) && hasFileUploaded" class="btn doc-btn-remove p-button-sm" (click)="deleteDocument()" label="{{'Shared.Button.Remove' | transloco}}"></button>
        </div>
    </div>
</div>
