<div class="section-back" [ngStyle]="getBackground()" [ngClass]="getBackgroundWidth()"></div>
<div class="section d-block" (window:resize)="onResize()" [ngClass]="getSectionStyling() + ' ' + getTabOrientation()">
    <div class="position-relative" [class.scroll-enabled]="isScrollable">
        <button class="tab-btn" (click)="scrollPrevious($event)" *ngIf="isScrollable">
            <span class="pi pi-chevron-left"></span>
        </button>
        <button class="tab-btn right" (click)="scrollNext($event)" *ngIf="isScrollable">
            <span class="pi pi-chevron-right"></span>
        </button>
    </div>

    <p-tabView *ngIf="IsFormEditableStyle()" #tabView [(activeIndex)]="activeTabIndex">
        <p-tabPanel header="{{ container.TitleResourceId | transloco }}" *ngFor="let container of TabSectionConfiguration.Containers;
                let i = index" [selected]="i === 0">
            <fw-container [containerConfiguration]="container"
                          [FormEditStyle]="FormEditStyle"></fw-container>
        </p-tabPanel>
    </p-tabView>

    <p-tabView *ngIf="!IsFormEditableStyle()" #tabView [(activeIndex)]="activeTabIndex">

        <p-tabPanel header="{{ container.TitleResourceId | transloco }}" *ngFor="let container of TabSectionConfiguration.Containers;
        let i = index" [selected]="i === 0">

            <ng-template pTemplate="content">
                <fw-container [containerConfiguration]="container"
                              [FormEditStyle]="FormEditStyle"></fw-container>
            </ng-template>
        </p-tabPanel>

    </p-tabView>
</div>
